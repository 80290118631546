




























import { defineComponent, ref, useFetch } from "@nuxtjs/composition-api";
import { CacheTagPrefix, useCache } from "@vue-storefront/cache";
import { useContent } from "~/composables";
import { useConfig } from "~/composables";
import type { CmsBlock } from "~/modules/GraphQL/types";

export default defineComponent({
  name: "CategoryListIcon",
  setup() {
    const { addTags } = useCache();
    const { loadBlocks } = useContent();
    const blocks = ref<CmsBlock[]>([]);
    const blockLiContent = ref([]);
    const { config } = useConfig();
    const isLoaded = ref(false);
    const baseUrl = config.value.secure_base_url;
    const placeholder = ref(
      require("@/assets/images/product_placeholder.webp"),
    );

    const { fetch, fetchState } = useFetch(async () => {
      blocks.value = await loadBlocks({ identifiers: ["category-list-icon"] });
      const re = /<li>(.*?)<\/li>/gs;
      const str = blocks?.value?.[0]?.content;
      let match;
      let i = 0;

      while ((match = re.exec(str)) !== null) {
        const a_herf = /<a\s+(?:[^>]*?\s+)?href="([^"]*)"/.exec(match?.[0]);
        const img_src = /img\s+.*?src="([^"]*)"/.exec(match?.[0]);
        const title = match?.[0].replace(/(<([^>]+)>)/gi, "");
        blockLiContent.value[i] = {
          link: a_herf?.[1].replace(baseUrl, "") ?? null,
          img: img_src[1] ?? null,
          title: title ?? null,
        };
        if (i == match.length - 1) {
          isLoaded.value = true;
        }
        i++;
      }

      const tags = [
        { prefix: CacheTagPrefix.View, value: "home-category-list-icon" },
      ];
      addTags([...tags]);
    });
    fetch();

    return {
      blocks,
      blockLiContent,
      isLoaded,
      placeholder,
    };
  },
});
